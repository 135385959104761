import { Show, For, splitProps } from 'solid-js';
import { Button } from '@troon/ui';
import { IconTag } from '@troon/icons';
import type { ComponentProps, ParentProps } from 'solid-js';

type ButtonProp<T extends keyof ComponentProps<typeof Button>> = ComponentProps<typeof Button>[T];

const appearances: Array<ButtonProp<'appearance'>> = [
	'primary',
	'secondary',
	'secondary-current',
	'transparent',
	'transparent-current',
];
const sizes: Array<ButtonProp<'size'>> = ['lg', 'sm'];

export default function ButtonsPage() {
	return (
		<div class="flex flex-col gap-8">
			<h1 class="text-3xl font-semibold">Buttons</h1>

			<h2 class="text-xl font-semibold">Actions</h2>
			<div class="grid gap-8">
				<Example showAppearance action={undefined} />
				<Example showAppearance action="danger">
					Danger
				</Example>
			</div>

			<h2 class="text-xl font-semibold">Sizes</h2>
			<div class="flex flex-col gap-4">
				<For each={sizes}>
					{(size) => (
						<Grid>
							<For each={appearances}>
								{(appearance) => (
									<div class="flex flex-col gap-2">
										<Button size={size} appearance={appearance}>
											<IconTag /> Submit
										</Button>
									</div>
								)}
							</For>
						</Grid>
					)}
				</For>
			</div>
		</div>
	);
}

function Example(exProps: ComponentProps<typeof Button> & { showAppearance?: boolean }) {
	const [rest, props] = splitProps(exProps, ['showAppearance']);
	return (
		<div class="flex flex-col gap-4">
			<Grid>
				<For each={appearances}>
					{(appearance) => (
						<Button {...props} appearance={appearance}>
							<Show when={rest.showAppearance}>
								{appearance!.slice(0, 1).toUpperCase()}
								{appearance!.slice(1)}
							</Show>{' '}
							{props.children}
						</Button>
					)}
				</For>
			</Grid>
			<Grid>
				<For each={appearances}>
					{(appearance) => (
						<Button {...props} appearance={appearance} disabled>
							<Show when={rest.showAppearance}>
								{appearance!.slice(0, 1).toUpperCase()}
								{appearance!.slice(1)}
							</Show>{' '}
							{props.children}
						</Button>
					)}
				</For>
			</Grid>
		</div>
	);
}

function Grid(props: ParentProps) {
	return <div class="flex flex-row flex-wrap gap-4">{props.children}</div>;
}
